import React from "react";
import tw from "twin.macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";

const Heading = tw(SectionHeading)`mb-4`;
const Subheading = tw(SubheadingBase)`text-center text-2xl mt-10 mb-6`;

const Text = tw.p`text-justify max-w-xl`;
const Column = tw.div`flex flex-col items-center`;

const AboutPage = () => (
  <Container>
    <ContentWithPaddingXl>
      <Column>
        <Heading>About Us</Heading>
        <Text>
          Introducing a mortgage firm whose people have decades of experience.
          Our people are dedicated to providing you a loan that works for you
          with exceptional service. We make the difference.
          <br />
          <br />
          Each one of us brings something special to work every day. We cover
          all the bases in your search for excellent service and competitive
          rates.
          <br />
          <br />
          Our manager, Gwen Chen (NMLS #2006474), has more than 20 years of real
          estate title experience.
          <br />
          <br />
          Our loan officers have many years of experience in real estate and
          mortgage loan origination and are committed to helping you.
        </Text>
        <Subheading>Equal Housing Opportunity Statement</Subheading>
        <Text>
          We are pledged to the letter and spirit of U.S. policy for the
          achievement of equal housing opportunity throughout the Nation. We
          encourage and support an affirmative advertising and marketing program
          in which there are no barriers to obtaining housing because of race,
          color, religion, sex, handicap, familial status, or national origin.
        </Text>
      </Column>
    </ContentWithPaddingXl>
  </Container>
);

export default AboutPage;
