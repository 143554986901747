import FAQTemplate from "components/FAQTemplate.js";

const faqs = {
  subheading: "",
  heading: "Frequently Asked Questions",
  description: "",
  faqs: [
    {
      question: "Which type of mortgage is best for me?",
      answer: `This question will help you determine whether youre talking to just a producer 
        — a salesperson — or a quality adviser. When you ask, "What are my options?" for each 
        type of loan discussed, the mortgage lender should tell you the pros and the cons in 
        light of your situation.`,
    },
    {
      question: "How much downpayment will I need?",
      answer: `A 20% down payment is every lender's ideal, but it's not always required. 
        Qualified buyers can find mortgages with as little as 3% down, or even no down payment. 
        Again, there are considerations for every down payment option. The best lenders will 
        take the time to walk you through the choices.`,
    },
    {
      question: "What is my interest rate?",
      answer: `You probably already planned to ask this mortgage question. 
      It's the one benchmark we all understand. 
      By the way, if you're considering an adjustable-rate mortgage rather than a fixed-rate loan, 
      you'll want to ask: How often is the payment interest rate adjusted? What is the maximum 
      annual adjustment? What is the highest cap on the rate?
      `,
    },
    {
      question: "Are you doing a hard credit check on me today?",
      answer: `It's always good to know when the lender is going to perform a "hard" credit check, 
        called a Hard Inquiry. That type of payment history inquiry shows up on your credit report. 
        Lenders need to do this to give you a firm interest rate quote.
        When you're shopping more than one lender, you'll want these hard credit pulls to occur 
        within a short period of time — say within just a week or so — to minimize the impact 
        on your credit score.`,
    },
    {
      question: "Do you charge for an interest rate lock?",
      answer: `Once you've decided on a lender, you may want to lock in your interest rate at some point. 
      This ensures that it doesn't go up — though it won't go down, either.
      The answer you're looking for on a typical home loan (not a construction loan) is: 
      There's no charge for an interest rate lock. `,
    },
    {
      question: "Will I have to pay mortgage insurance?",
      answer: `If you put down less than 20%, the answer will probably be "Yes."
      Even if the mortgage insurance is "lender paid," it's likely passed on as 
      a cost built into your mortgage payment, which increases your rate and monthly payment. 
      You'll want to know just how much mortgage insurance will cost and if it's an upfront 
      or ongoing charge, or both.
      Then, ask the lender what your options are. The answer may be just, "Make a bigger down payment."
      Or you may find there are other loan programs that you might qualify for that don't require mortgage insurance.      `,
    },
  ],
};

const FAQPage = () => FAQTemplate(faqs);

export default FAQPage;
