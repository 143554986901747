import React from "react";
import tw from "twin.macro";
import { SectionHeading } from "components/misc/Headings.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";

const Heading = tw(SectionHeading)`mb-4`;
const Text = tw.p`text-justify max-w-3xl`;

const Column = tw.div`flex flex-col items-center`;

const FHAPurchasePage = () => (
  <Container>
    <ContentWithPaddingXl>
      <Column>
        <Heading>FHA Purchase</Heading>
        <Text>
          An FHA loan is available in fixed rate and adjustable rate.
          <br />
          <br />
          Fixed Rate
          <ul>
            <li>
              Do you have less than perfect credit and still want to qualify for
              a home loan? Would you like an assumable loan?
            </li>
            <li>
              Are you lacking cash to use as a down payment, or have less than
              20% equity in your home?
            </li>
          </ul>
          FHA home loans are one of the easiest loans to qualify for, especially
          if you have faced financial difficulty in the past, have less than
          perfect credit, or lack equity or cash for closing. These government
          insured loan programs enable lenders to take more risk with qualifying
          people for a loan, which opens up the opportunity for more Americans
          to own their own home. You can select a fixed rate using a 30, 25, 20
          or 15 year term.
          <br />
          <br />
          Adjustable Rate
          <ul>
            <li>
              Do you want to take advantage of today&rsquo;s lowest possible
              interest rate, but have struggled with your credit in the past?
            </li>
            <li>
              Do you plan on refinancing, or moving in the next five years?
            </li>
          </ul>
          An FHA variable rate loan gives you the benefit of today&rsquo;s low
          interest rate,. You can also refinance up to 97.75% of your primary
          home&rsquo;s current value. If you are planning a move in the next few
          years, or simply want to take advantage or today&rsquo;s low rates,
          this is a great option for you.
        </Text>
      </Column>
    </ContentWithPaddingXl>
  </Container>
);

export default FHAPurchasePage;
