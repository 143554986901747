import React from "react";
import tw from "twin.macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";

import { addressLine1, addressLine2, telephoneFormatted } from "helpers/const";

const Heading = tw(SectionHeading)`mb-4`;
const Subheading = tw(SubheadingBase)`text-center text-2xl mt-10 mb-6`;
const Text = tw.p`text-justify max-w-3xl`;

const Column = tw.div`flex flex-col items-center`;

const PrivacyPage = () => (
  <Container>
    <ContentWithPaddingXl>
      <Column>
        <Heading>Online Privacy Policy</Heading>
        <Text>
          Safeguarding our customers&rsquo; privacy is very important to us. As
          we continue to improve and expand our services, we recognize our
          customers&rsquo; need and desire to preserve their privacy and
          confidentiality. We have adopted standards that help maintain and
          preserve the confidentiality of customers&rsquo; nonpublic personal
          information. The following Statement affirms our continued efforts to
          safeguard customer information.
        </Text>
        <Subheading>Information We Collect</Subheading>
        <Text>
          We maintain physical, electronic and procedural safeguards designed to
          protect your personal information. We gather nonpublic personal
          information about our customers as may be necessary to conduct
          business with our customers. We do not disclose any nonpublic
          information about our customers or former customers to any third
          party, except as permitted by law. We collect nonpublic personal
          information about you from the following sources:
          <br />
          <br />
          Information we receive from you on applications or other forms, over
          the telephone or in face-to-face meetings, and via the Internet.
          Examples of information we receive from you include your name,
          address, telephone number, social security number, credit history and
          other financial information.
          <br />
          <br />
          Information about your transactions with us or others. Examples of
          information relating to your transactions include payment histories,
          account balances and account activity.
          <br />
          <br />
          Information we receive from a consumer reporting agency. Examples of
          information from consumer reporting agencies include your credit
          score, credit reports and other information relating to your
          creditworthiness
          <br />
          <br />
          From employers and others to verify information you have given to us.
          Examples of information provided by employers and others include
          verification of employment, income or deposits.
        </Text>
        <Subheading>Information We Disclose</Subheading>
        <Text>
          Your personal information will only be retained for the purpose of
          providing you with our response to your query and will not be made
          available to any third party except as necessary to be disclosed to
          any related entity for the purpose intended or as required to be
          disclosed under law.
          <br />
          <br />
          We treat data as confidential within our firm and require a strict
          adherence of all our employees to data protection and our
          confidentiality policies.
          <br />
          <br />
          All visitors, however, should be aware that our website may contain
          links to other sites that are not governed by this or any other
          privacy statement.
        </Text>
        <Subheading>Questions</Subheading>
        <Text>
          If you have any questions or concerns about the integrity of your
          account information, or any other aspect of our business operations,
          please do not hesitate to write, call or email to:
          <br />
          <br />
          Verity Loans, LLC
          <br />
          Attention: Compliance Officer
          <br />
          Address: {addressLine1}, {addressLine2}
          <br />
          Tel: {telephoneFormatted}
        </Text>
        <Subheading>Changes to This Policy</Subheading>
        <Text>
          This policy is effective January 20th, 2022. We reserve the right to
          alter this privacy policy statement at our discretion. Any change to
          this policy statement will be posted on our web site in a timely
          manner
        </Text>
      </Column>
    </ContentWithPaddingXl>
  </Container>
);

export default PrivacyPage;
