import React, { useEffect, useRef, useState } from "react";
import tw from "twin.macro";
import { Link } from "react-router-dom";
import "style.css";

import logo from "images/logo-full.png";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

import { telephoneFormatted, telephoneRaw } from "helpers/const";

const NavLink = tw.a`
  my-2 mx-4
  font-semibold tracking-wide transition-default duration-300
  pb-1 border-b-2 border-transparent text-primary-900 hover:border-primary-500 active:text-primary-500
  whitespace-nowrap
`;

const NavLinkDropdown = tw.a`
  my-2 ml-4
  font-semibold tracking-wide transition-default duration-300
  pb-1 border-b-2 border-transparent text-primary-900 hover:border-primary-500 active:text-primary-500
  whitespace-nowrap
`;

const useOutsideAlerter = (ref, onClickOutside) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    };
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
};

const Header = (props) => {
  const [expanded, setExpanded] = useState(false);
  const ref = useRef(null);
  useOutsideAlerter(ref, () => setExpanded(false));
  return (
    <div ref={ref}>
      <Navbar
        className="nav"
        bg="light"
        expand="md"
        fixed="top"
        expanded={expanded}
        style={{
          boxShadow: "0 2px 4px 0 rgba(0,0,0,.2)",
          padding: 8,
        }}
      >
        <Navbar.Brand as={Link} to="/">
          <img
            src={logo}
            width="220"
            height="30"
            className="d-inline-block align-top"
            alt="Verity Loans logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse className="justify-content-end">
          <Nav className="ml-auto">
            <NavLink as={Link} to="/about" onClick={() => setExpanded(false)}>
              About
            </NavLink>
            <NavDropdown
              title={<NavLinkDropdown>Buy a Home</NavLinkDropdown>}
              className="navdropdown"
            >
              <NavDropdown.Item
                as={Link}
                to="/home-buyers-guide"
                onClick={() => setExpanded(false)}
              >
                Home Buyer&rsquo;s Guide
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/conventional-purchase"
                onClick={() => setExpanded(false)}
              >
                Conventional Purchase
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/fha-purchase"
                onClick={() => setExpanded(false)}
              >
                FHA Purchase
              </NavDropdown.Item>
            </NavDropdown>
            <NavLink
              as={Link}
              to="/refinance"
              onClick={() => setExpanded(false)}
            >
              Refinance
            </NavLink>
            <NavLink
              as={Link}
              to="/faq"
              className="mr-3"
              onClick={() => setExpanded(false)}
            >
              FAQ
            </NavLink>
            <NavLink
              href={"tel:" + telephoneRaw}
              onClick={() => setExpanded(false)}
            >
              {telephoneFormatted}
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
