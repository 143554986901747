import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "style.css";
import ScrollToTop from "helpers/ScrollToTop";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import HomePage from "pages/HomePage";
import AboutPage from "pages/AboutPage";
import FAQPage from "pages/FAQPage";
import HomeBuyersGuidePage from "pages/HomeBuyersGuidePage";
import ConventionalPurchasePage from "pages/ConventionalPurchasePage";
import FHAPurchasePage from "pages/FHAPurchasePage";
import RefinancePage from "pages/RefinancePage";
import PrivacyPage from "pages/PrivacyPage";
import Header from "components/Header";
import Footer from "components/Footer";

const App = () => (
  <Router>
    <ScrollToTop />
    <Header />
    <AnimationRevealPage disabled>
      <Routes>
        <Route path="/about" element={<AboutPage />} />
        <Route path="/home-buyers-guide" element={<HomeBuyersGuidePage />} />
        <Route
          path="/conventional-purchase"
          element={<ConventionalPurchasePage />}
        />
        <Route path="/fha-purchase" element={<FHAPurchasePage />} />
        <Route path="/refinance" element={<RefinancePage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/" element={<HomePage />} />
      </Routes>
      <Footer />
    </AnimationRevealPage>
  </Router>
);

export default App;
