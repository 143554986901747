import React from "react";
import tw from "twin.macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";

const Heading = tw(SectionHeading)`mb-4`;
const Subheading = tw(SubheadingBase)`text-center text-2xl mt-10 mb-6`;
const Description = tw(SectionDescription)`text-center mt-4 mx-auto`;
const Text = tw.p`text-justify max-w-3xl`;

const Column = tw.div`flex flex-col items-center`;

const HomeBuyersGuidePage = () => (
  <Container>
    <ContentWithPaddingXl>
      <Column>
        <Heading>Home Buyer&rsquo;s Guide</Heading>
        <Subheading>3 Secrets to Qualify for a Mortgage Loan</Subheading>
        <Description>Credit Scores</Description>
        <Text>
          Lenders will look closely at your credit scores and how well you are
          paying your monthly obligations that show up on your credit reports.
          Generally speaking a minimum score of 640 across all three consumer
          reporting agencies (CRAs) is required to qualify for a mortgage.
          <br />
          <br />
          There are programs out there that go down to 580 in some special
          circumstances. But don&rsquo;t hold your breath waiting for loan
          approval as the lenders will make you jump thru many hula hoops before
          that happens.
          <br />
          <br />
          If you have scores that are close we can work with you to get the
          scores where they need to be. I can refer you to a reliable, low cost,
          program that, most of the time, can boost your scores to where they
          need to be in a few months.
          <br />
          <br />
          Knowledge is power. Know your scores BEFORE you apply for any loan. If
          you find errors that need to be addressed it may take up to 90 days
          for corrections to show up so start early so when the time comes to
          start looking for a home you will confidence that your loan officer
          will be able to issue a preapproval letter to your Realtor when you
          put in an offer.
        </Text>
        <Description>Debt Ratio</Description>
        <Text>
          The second secret is your Debt Ratio. Your debt ratio is a percentage
          of your gross monthly income prior to deductions for withholding,
          social security…etc. Your debt ratio includes car payments, credit
          card payments, mortgage payments and anything else that normally shows
          up on your credit reports. Student loans or installment loans for
          example, also show up on your reports.
          <br />
          <br />
          As a rule of thumb, if you keep your total monthly payments at or
          below 45% of your gross monthly income you will be approved if your
          scores are high enough and you meet the down payment requirements. As
          an example if you gross $10,000 monthly and the combined total of your
          monthly car payments, credit card payments and mortgage payments is at
          or below $4,500 you should quality for a mortgage loan. Of course
          there are exceptions to every rule. I have gotten loan approval with
          debt ratios as high as 55% in certain circumstances. Certain
          limitations apply, call for details.
        </Text>
        <Description>Loan to Value</Description>
        <Text>
          The third secret is Loan to Value. If the purchase price or appraised
          value is 100K and the loan amount is 80K then the loan to value is
          80%. Those in the business abbreviate loan to value as LTV. 80% LTV is
          the gold standard in mortgage loans. Before about 30 years ago that
          was almost the only kind of mortgage loan that was made. You went to
          the bank with 20% in cash and they made an 80% LTV loan and the bank
          was very happy because it&rsquo;s very rare for them to lose money
          even if the loan goes South when there is 20% down on the part of the
          buyer.
          <br />
          <br />
          Today we regularly make loans all the way up to 96 ½% LTV when there
          is a need due to lack of down payment funds. Let our experienced team
          help you purchase the home of your dreams.
        </Text>
        <Subheading>Determining If You&rsquo;re Ready to Buy a Home</Subheading>
        <Text>
          Buying a house is a big commitment, so before you start house hunting
          and comparing mortgage rates, take the time to examine your current
          situation and how it could change in the future.
          <br />
          <br />
          Ask yourself:
          <ul>
            <li>
              Are you planning on any major life changes, like changing jobs or
              starting a family, in the next few years that could impact your
              financial situation?
            </li>
            <li>
              Can you commit to staying in a home for at least five years?
            </li>
            <li>Do you have a stable income?</li>
            <li>
              Are you confident you can handle house repairs (or can take the
              time to learn), or are you willing to pay a specialist when
              something breaks?
            </li>
          </ul>
        </Text>
        <Subheading>
          How long do I need to own my house before it pays off?
        </Subheading>
        <Text>
          Generally, we recommend you only consider buying a house if you plan
          to live there for at least five years, but this depends on a lot of
          factors, like the housing market, rental prices and how much equity
          you have in the house.
        </Text>
        <Subheading>Buying vs. Renting a House</Subheading>
        <Text>
          Each option has its benefits, so consider what matters to you.
          <br />
          <br />
          Benefits of Buying
          <ul>
            <li>
              No landlord means you can make your house a home you want without
              anyone else&rsquo;s approval.
            </li>
            <li>
              Unlike rent payments, the interest you pay with your mortgage
              payments can be tax-deductible.
            </li>
            <li>
              You can find a mortgage tailored to your budget and goals to keep
              your monthly payment from going up as the market changes.
            </li>
            <li>
              You build equity as you make your monthly mortgage payments.
            </li>
          </ul>
          Benefits of Renting
          <ul>
            <li>
              Your landlord is often responsible for home repairs and upgrades.
            </li>
            <li>
              You won&rsquo;t have to buy homeowners insurance or pay property
              taxes on your home.
            </li>
            <li>
              Moving can be easier since you won&rsquo;t have to sell your home
              or find renters.
            </li>
          </ul>
        </Text>
      </Column>
    </ContentWithPaddingXl>
  </Container>
);

export default HomeBuyersGuidePage;
