import React from "react";
import { Link } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import Background from "../images/stock-family.jpg";

const containerLiteral = tw`relative -mx-8 mt-8 bg-center bg-cover`;
const Container = styled.div`
  ${containerLiteral}
  background-image: url(${Background});
  min-height: 95vh;
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-200 opacity-15`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between flex-row`;
const LeftColumn = tw.div`flex flex-col block`;
const Links = tw.div`flex mt-32`;

const headingLiteral1 = tw`text-3xl text-left text-4xl font-black text-gray-100 leading-none`;
const headingLiteral2 = tw`inline-block mt-2`;
const Heading = styled.h1`
  ${headingLiteral1}
  span {
    ${headingLiteral2}
  }
`;

const slantedBackgroundLiteral1 = tw`relative text-primary-700 px-4 -mx-4 py-2`;
const slantedBackgroundLiteral2 = tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`;
const SlantedBackground = styled.span`
  ${slantedBackgroundLiteral1}
  &::before {
    content: "";
    ${slantedBackgroundLiteral2}
  }
`;

const Notification = tw.span`
  inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 
  font-medium text-sm
`;

const PrimaryAction = tw.a`
  px-8 py-3 mx-2 mt-16 
  text-sm sm:text-base sm:mt-16 sm:px-8 
  bg-primary-700 text-gray-100 font-bold rounded-default shadow-default transition-default duration-300 
  hocus:bg-primary-500 hocus:text-gray-100 focus:ring
`;

const HomePage = () => (
  <Container>
    <OpacityOverlay />
    <HeroContainer>
      <TwoColumn>
        <LeftColumn>
          <Notification>Proudly serving all of Ohio and Florida</Notification>
          <Heading>
            <span>Loans That</span>
            <br />
            <span>Work For </span>
            <br />
            <SlantedBackground>You</SlantedBackground>
          </Heading>
          <Links>
            <PrimaryAction as={Link} to="/home-buyers-guide">
              Purchase
            </PrimaryAction>
            <PrimaryAction as={Link} to="/refinance">
              Refinance
            </PrimaryAction>
          </Links>
        </LeftColumn>
      </TwoColumn>
    </HeroContainer>
  </Container>
);

export default HomePage;
