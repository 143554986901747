import React from "react";
import tw from "twin.macro";
import { Link } from "react-router-dom";

import LogoImage from "../images/logo-light.png";
import EqualHousingOpportunityIcon from "../images/equal-housing-opportunity-logo.png";

import {
  addressLine1,
  addressLine2,
  telephoneRaw,
  telephoneFormatted,
  nmlsNumber,
} from "helpers/const";

const Container = tw.div`relative -mx-8 -mb-8 px-8`;
const Content = tw.div`max-w-screen-xl mx-auto pt-16 pb-8`;
const FiveColumns = tw.div`flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(
  Column
)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h6`font-bold`;

const NavLink = tw.a`
  font-medium tracking-wide transition-default duration-300
  pb-1 border-b-2 border-primary-200 text-primary-900 hover:border-primary-500 active:text-primary-500
`;
const ColumnLink = tw.a`
  font-bold tracking-wide transition-default duration-300
  pb-1 border-b-2 border-primary-200 text-primary-900 hover:border-primary-500 active:text-primary-500
`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-32`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`;
const EqualHousingOpportunity = tw.img`w-16`;

const Divider = tw.div`my-8 border-b-2 border-gray-800`;

const getYear = () => new Date().getFullYear();

const Footer = () => {
  return (
    <Container>
      <Content>
        <FiveColumns>
          <WideColumn>
            <LogoContainer>
              <LogoImg src={LogoImage} />
            </LogoContainer>
            <CompanyDescription>
              {addressLine1}
              <br />
              {addressLine2}
            </CompanyDescription>
          </WideColumn>
          <Column>
            <ColumnHeading>Contact Us</ColumnHeading>
            <NavLink href={"tel:" + telephoneRaw}>{telephoneFormatted}</NavLink>
            <br />
            <br />
            <ColumnHeading>
              <ColumnLink as={Link} to="/privacy">
                Privacy Policy
              </ColumnLink>
            </ColumnHeading>
          </Column>
        </FiveColumns>
        <Divider />
        <CopyrightAndCompanyInfoRow>
          <Column>
            <NavLink
              href={
                "https://www.nmlsconsumeraccess.org/Home.aspx/SubSearch?searchText=" +
                nmlsNumber
              }
            >
              NMLS #{nmlsNumber}
            </NavLink>{" "}
            <br />
            <br /> OH RM.804633.000 <br /> FL MBR4977
            <br />
            <br />
            &copy; Copyright 2020&ndash;{getYear()}, Verity Loans LLC
            <br />
            <br />
          </Column>
          <WideColumn>
            The contents within the website are presented for informational
            purposes only. This is not a commitment to lend or extend credit.
            Information and/or dates are subject to change without notice. All
            loans are subject to credit approval. Certain limitations apply,
            call for details. <br /> <br />
            <NavLink href="https://www.nmlsconsumeraccess.org">
              www.nmlsconsumeraccess.org
            </NavLink>
          </WideColumn>
          <Column>
            <EqualHousingOpportunity src={EqualHousingOpportunityIcon} />
          </Column>
        </CopyrightAndCompanyInfoRow>
      </Content>
    </Container>
  );
};

export default Footer;
