import React from "react";
import tw from "twin.macro";
import { SectionHeading } from "components/misc/Headings.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";

const Heading = tw(SectionHeading)`mb-4`;
const Text = tw.p`text-justify max-w-3xl`;
const Column = tw.div`flex flex-col items-center`;

const ConventionalPurchasePage = () => (
  <Container>
    <ContentWithPaddingXl>
      <Column>
        <Heading>Conventional Purchase Loan</Heading>
        <Text>
          A conventional loan is available in fixed rate and adjustable rate.
          <br />
          <br />
          Fixed Rate
          <ul>
            <li>
              Do you want to be able to depend on paying one principal and
              interest payment amount over the course of your home loan, despite
              possible fluctuations in the interest rate?
            </li>
            <li>
              Do you plan on remaining in your home for a long period of time?
            </li>
          </ul>
          Adjustable Rate Loans
          <ul>
            <li>
              We suggest adjustable rate loans to help our clients with the
              lowest payments if they don&rsquo;t plan to stay in their homes
              longer than about 5 years.
            </li>
          </ul>
        </Text>
      </Column>
    </ContentWithPaddingXl>
  </Container>
);

export default ConventionalPurchasePage;
