import React from "react";
import tw from "twin.macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";

const Heading = tw(SectionHeading)`mb-4`;
const Subheading = tw(SubheadingBase)`text-center text-2xl mt-10 mb-6`;
const Description = tw(SectionDescription)`text-center mt-4 mx-auto`;
const Text = tw.p`text-justify max-w-3xl`;

const Column = tw.div`flex flex-col items-center`;

const RefinancePage = () => (
  <Container>
    <ContentWithPaddingXl>
      <Column>
        <Heading>Refinance</Heading>
        <Subheading>Reasons to Refinance</Subheading>
        <Text>
          The first step in deciding whether you should refinance is to
          establish your goals. The most common reasons for refinancing a
          mortgage are to take cash out, get a lower payment or shorten your
          mortgage term.
        </Text>
        <Description>Take Cash Out</Description>
        <Text>
          Refinancing your mortgage is a great way to use the equity you have in
          your home. With a cash-out refinance, you refinance for a higher loan
          amount than what you owe and pocket the difference. Any proceeds you
          receive are tax-free.
          <br />
          <br />
          Many homeowners use cash from their home to consolidate high-interest
          credit card debt and student loan debt. You can also take cash out to
          finance home improvements, education or whatever you need. Since
          mortgage interest rates are typically lower than interest rates on
          other debts, a cash-out refinance can be a great way to consolidate
          debt. However, you should not assume that any debt will be eliminated
          by refinancing since the debts are consolidated into a new loan. As a
          result of refinancing, your total finance charges may be higher over
          the life of the loan. Certain limitations apply, call for details.
          <br />
          <br />
          Additionally, mortgage interest is tax-deductible, but the interest on
          other debts usually isn&rsquo;t. Consult with a tax professional on
          any potential savings and tax advice.
          <br />
          <br />
          You may be able to take cash from your home if you&rsquo;ve been
          paying on the loan long enough to build equity. Additionally, you may
          be able to do a cash-out refinance if your property value has
          increased; a higher value on your home means your lender can give you
          more money to finance it.
        </Text>
        <Description>Get a Lower Payment</Description>
        <Text>
          A lower mortgage payment means more room in your budget for other
          things. There are a few ways you can lower your payment by
          refinancing.
          <br />
          <br />
          First, you may be able to refinance with a lower rate. If rates now
          are lower than they were when you bought your home, it&rsquo;s worth
          talking to your lender to see what your interest rate could be.
          Getting a lower rate means lowering the interest portion of your
          monthly payment — and big interest savings in the long run.
          <br />
          <br />
          Second, you could refinance to get rid of mortgage insurance — a
          monthly fee you pay to protect your lender in the event that you
          default on the loan. Mortgage insurance is usually only required when
          you put down less than 20%. You could save hundreds of dollars a month
          by refinancing to stop paying monthly mortgage insurance.
          <br />
          <br />
          Third, you can get a lower payment by changing your mortgage term.
          Lengthening your term stretches out your payments over more years,
          which makes each payment smaller.
          <br />
          <br />
          There may be other ways you can get a lower payment, so it&rsquo;s
          always worth checking with your lender to see how they can help you
          get a payment that fits your current budget.
        </Text>
        <Description>Shorten Your Mortgage Term</Description>
        <Text>
          Shortening your mortgage term is a great way to save money on
          interest. Often, shortening your term means you&rsquo;ll receive a
          better interest rate. A better interest rate and fewer years of
          payments mean big interest savings in the long run.
          <br />
          <br />
          An important thing to know about shortening your term is that it may
          increase your monthly mortgage payment. However, less of your payment
          will go toward interest, and more of it will go toward paying down
          your loan balance. This allows you to build equity and pay off your
          home faster.
        </Text>
        <Subheading>
          How long should I own my home before refinancing?
        </Subheading>
        <Text>
          In most cases, you&rsquo;ll need to be in your current home for at
          least a year before getting a significant financial benefit from
          refinancing.
        </Text>
        <Subheading>Things You Need to Evaluate Before Refinancing</Subheading>
        <Text>
          Once you have a clear goal in mind, you&rsquo;ll want to evaluate your
          financial situation. There are four keys things to look at: your
          credit score, your monthly mortgage payment, the value of your home
          and your debt-to-income ratio (DTI).
        </Text>
        <Description>Your Credit Score</Description>
        <Text>
          Knowledge is power. Knowing your credit score will help you understand
          what mortgage refinance options you could be eligible for. Ask us for
          help.
        </Text>
        <Description>Your Monthly Mortgage Payment</Description>
        <Text>
          Knowing how your monthly mortgage payment fits into your budget will
          help you evaluate your options. If you&rsquo;re taking cash out or
          shortening your term, for instance, it&rsquo;s a good idea to know how
          much wiggle room you have in your budget for a higher monthly payment.
          If your goal is to get a lower monthly payment, it&rsquo;s important
          to decide how much you need to lower your payment for the refinance to
          be worthwhile.
        </Text>
        <Description>The Value of Your Home</Description>
        <Text>
          Before you refinance, you&rsquo;ll want to do a bit of research to
          estimate how much your house is worth. Your lender can&rsquo;t lend
          you more than the home is worth, so an appraisal value that comes back
          lower than expected can impact your ability to refinance — especially
          if you&rsquo;re looking to take cash out or remove mortgage insurance.
          We can help you with this. Just ask us.
          <br />
          <br />
          Knowing the value of your home can tell you how much equity you have.
          To figure this out, just subtract your current mortgage balance from
          the estimated value of your home.
        </Text>
      </Column>
    </ContentWithPaddingXl>
  </Container>
);

export default RefinancePage;
